const CursorPause = () => (
  <svg id="cursor-video-pause" width="80" height="80" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M35.1452 28.9627H33.527C33.0228 28.9627 32.6141 29.3714 32.6141 29.8755V50.083C32.6141 50.5872 33.0228 50.9959 33.527 50.9959H35.1452C35.6494 50.9959 36.0581 50.5872 36.0581 50.083V29.8755C36.0581 29.3714 35.6494 28.9627 35.1452 28.9627Z" />
      <path d="M46.5975 28.9627H44.9792C44.4751 28.9627 44.0664 29.3714 44.0664 29.8755V50.083C44.0664 50.5872 44.4751 50.9959 44.9792 50.9959H46.5975C47.1017 50.9959 47.5104 50.5872 47.5104 50.083V29.8755C47.5104 29.3714 47.1017 28.9627 46.5975 28.9627Z" />
      <path d="M40.083 3.9834C59.9585 3.9834 76.0996 20.1245 76.0996 40C76.0996 59.8755 59.9585 76.0166 40.083 76.0166C20.2075 76.0166 4.06638 59.8755 4.06638 40C4.06638 20.1245 20.2075 3.9834 40.083 3.9834ZM40.083 0.248962C18.1328 0.248962 0.33194 18.0498 0.33194 40C0.33194 61.9502 18.1328 79.751 40.083 79.751C62.0332 79.751 79.834 61.9502 79.834 40C79.834 18.0498 62.0332 0.248962 40.083 0.248962Z" />
    </g>
  </svg>
);

export default CursorPause;
